@import "../Assets/Functions.less";
@import (inline) "../Assets/Utilities.css";
@mobile: ~"(max-width: 767px)";
@tablet: ~"(min-width: 768px)";
@tablet-only: ~"(min-width: 768px) and (max-width: 1199px)";
@desktop: ~"(min-width: 1200px)";
@mobileNavColor: #2c5ca8;
@castleDetailsColor: #2c5ca8;
@cloudUrl: "https://files.bookingonline.co.uk/image/upload/f_auto/themes/036/";
@cloudUrlNoAuto: "https://files.bookingonline.co.uk/image/upload/themes/036/";
/*#region fonts*/
.OpenSans, .BodyFont {
	font-family: 'Open Sans', sans-serif;
	font-weight: 400 !important;
}

.LondrindaSolidBlack {
	font-family: 'Londrina Solid', cursive;
	font-weight: 900;
}

html {
}

body {
	.BodyFont;
}

.nicefont {
}

.textContent {
	h1 {
		font-size: 20px;
		padding-top: 50px;
		.LondrindaSolidBlack;
		font-size: 55px;
		text-align: center;
	}
}

h2, h3, h4 {
}
/*#endregion fonts*/
form {
	max-width: 100vw;
	overflow: hidden;
}

.outer-wrapper {
	max-width: 100vw;
	position: relative;

	&--alt {
	}
}

.container {
	position: relative;

	@media @mobile {
		width: 100%;
	}

	@media @tablet {
		width: 100%;
	}

	@media @desktop {
		width: 1170px;
	}

	@media (min-width: 1310px) {
		padding-left: 55px;
		padding-right: 55px;
	}

	&--content {
		flex-grow: 1;
	}
}
/*#region topLinksMobile*/
#TopLinksMobile {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	background: @mobileNavColor;
	color: white;
	z-index: 20;
	padding: 0;
	border-bottom: 1px solid white;
	.BodyFont();

	.navbar {
		margin-bottom: 0;
	}

	@media @tablet {
		display: none !important;
	}

	#NavigationToggleText {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		padding: 14px 63px;
		text-align: right;
	}

	.navbar-toggle .icon-bar {
		background: white;
	}

	.row {
		padding-top: 10px;
	}

	.input-group {
		margin-bottom: 0;
	}
}

#MobileHomeLink {
	position: fixed;
	top: 10px;
	z-index: 10;
	background: transparent none repeat scroll 0 0;
	color: white;
	left: 10px;
	border-radius: 50%;
	border: 2px solid white;
}
/*#endregion topLinksMobile*/
.toplinks {
	margin-bottom: -5px;

	&__nav {
		display: flex;
		justify-content: space-between;
		align-items: baseline;
		flex-wrap: wrap;
		margin-bottom: 0;
		padding-left: 0;
	}

	&__li {
		position: relative;
		list-style: none;
		text-align: center;
		flex-grow: 1;

		&:nth-child(5n + 1) a {
			background-color: #f72223;
		}

		&:nth-child(5n + 2) a {
			background-color: #ffcd00;
		}

		&:nth-child(5n + 3) a {
			background-color: #0dafee;
		}

		&:nth-child(5n + 4) a {
			background-color: #1fae37;
		}

		&:nth-child(5n + 5) a {
			background-color: #f7af00;
		}

		.dropdown-toggle:focus {
			text-decoration: none;
		}
	}

	&__link {
		position: relative;
		display: block;
		padding: 15px 5px 20px;
		color: #fff;
		transition: all 0.3s;
		font-size: 20px;
		.LondrindaSolidBlack;
		text-shadow: 1px 1px 1px rgba(0,0,0,0.3);

		&:hover, &.active, &:focus {
			/*color: #ffc600;*/
			border-radius: 5px 5px 0 0;
			color: white;
			transform: translateY(-5px);
		}
	}

	&__dropdown {
		padding: 0;
		margin: 0;
		border: none;
		box-shadow: none;
		text-align: center;
		background-color: inherit !important;
		background: #000;
		width: 100%;
	}

	&__dropdown-li {
		display: block;
	}

	&__dropdown-link {
		position: relative;
		padding: 10px 10px !important;
		font-size: 16px;
		.LondrindaSolidBlack;

		&:hover, &:focus, &.active {
			background-color: black !important;
			color: #f7af00 !important;
		}
	}
}

.book-online {
	background: #ee1e19;
}

.top-bar {
	background-color: #0a2f6c;

	&__opening-times {
		color: white;
	}

	&__top-links a {
		color: white;
		margin-left: 20px;

		&:hover {
			color: gold;
		}
	}

	@media @mobile {
		padding-top: 70px;
		display: none;
	}
}

.banner-wrapper {
	background-image: url('@{cloudUrl}banner-bg.jpg');
	background-size: cover;
	padding-top: 70px
}

.videoSection {
	height: 882px;
	position:relative;

	@media @mobile {
		height: 500px;
	}

	&__bg{
		position:absolute;
		top:0;
		left:0;
		bottom:0;
		right:0;
		width:100%;
		height:100%;
		object-fit:cover;
	}


	&__video-left {
		position: absolute;
		bottom: 0;
		left: 0;
		transform: translate(-88%,5%);
		z-index: 5;
	}

	&__video__panel {
		position: relative;
		z-index: 10;

		&:before {
			content: "";
			display: block;
			width: 100%;
			padding-top: 56.25%;
		}

		iframe {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}
	}

	iframe {
	}

	&__video-right {
		position: absolute;
		bottom: 0;
		right: 0;
		transform: translate(81%,-12%);
		z-index: 5;
	}

	&__callUs {
		text-transform: uppercase;
		background-color: #fff;
		color: #f00;
		border-radius: 5px;
		padding: 10px 79px;
		font-family: 'Londrina Solid',cursive;
		font-weight: 900;
		font-size: 3rem;
		margin: 70px 70px 70px;
		display:inline-block;

		@media @mobile {
			margin: 31px 0 31px;
		}
	}
}

.testimonials {
	/*background-color: #edeff2;*/
	background-color: white;
	position: relative;
	padding: 30px 30px 60px;
	z-index: 1;
	-webkit-box-shadow: 0 5px 4px 0 rgba(49,59,73,.3);
	box-shadow: 0 5px 4px 0 rgba(49,59,73,.3);

	@media @mobile {
		margin-top: -40px;
		padding: 15px 15px 30px;
	}

	&__header {
		color: #ffcd00;
		.LondrindaSolidBlack;
		font-size: 5rem;
		margin-bottom: 41px;
	}

	&__view-all {
		background-color: #a7a7a7;
		margin-top: 40px;
		padding: 13px 39px;
		color: #fff;
		letter-spacing: 3px;
		font-family: 'Londrina Solid',cursive;
		font-weight: 900;
		font-size: 2rem;
	}

	&:before {
		content: "";
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAOAgMAAABiJsVCAAABS2lUWHRYTUw6Y29tLmFkb2JlLnhtcAAAAAAAPD94cGFja2V0IGJlZ2luPSLvu78iIGlkPSJXNU0wTXBDZWhpSHpyZVN6TlRjemtjOWQiPz4KPHg6eG1wbWV0YSB4bWxuczp4PSJhZG9iZTpuczptZXRhLyIgeDp4bXB0az0iQWRvYmUgWE1QIENvcmUgNS42LWMxMzggNzkuMTU5ODI0LCAyMDE2LzA5LzE0LTAxOjA5OjAxICAgICAgICAiPgogPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4KICA8cmRmOkRlc2NyaXB0aW9uIHJkZjphYm91dD0iIi8+CiA8L3JkZjpSREY+CjwveDp4bXBtZXRhPgo8P3hwYWNrZXQgZW5kPSJyIj8+IEmuOgAAAARnQU1BAACxjwv8YQUAAAABc1JHQgCuzhzpAAAADFBMVEUAAAAAAAAAAAAAAAA16TeWAAAABHRSTlMADQcaCWiadAAAAChJREFUCNdjuMDAIMAQxsCgwCDLAASqCUCCESjIwBQG4hMWTGBgCgAA8yMHEmDIg7sAAAAASUVORK5CYII=') /*diagonal-dots.png*/;
		background-size: 7px;
		z-index: -1;
	}

	&__link {
		text-align: center;
	}

	&__text {
		color: #808080;
	}

	.RotatorTestimonial {
		background: #fff;
		border-radius: 5px;
		border: 1px solid #cfcfcf;
		box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.15);
		margin: 0 8px;
		padding: 15px;
		text-align: center;
		overflow: hidden;

		@media @sm {
			padding: 30px;
		}
	}

	.RotatorTestimonialContent {
		display: flex;
		flex-direction: column-reverse;
		justify-content: space-between;

		time {
			display: none;
		}

		p:first-child {
			color: #fff;

			a {
				color: #2babbf;
				.LondrindaSolidBlack;
			}
		}
	}

	.slick-track {
		padding-bottom: 15px;
	}

	#RotatorTestimonialsHeader {
		margin-bottom: 15px;

		p, a {
			text-align: center;
			color: #333;
			font-size: 2rem;
			position: relative;
		}
	}

	.RotatorTestimonialRating {
		max-width: 280px;
		margin: 0 auto;
	}
}

.header {
	@width: 1170px;
	@height: 236px;
	position: relative;

	@media @tablet {
		padding-top: round(percentage(@height/@width),2);
	}

	@media @mobile {
		padding-top: 30px;
	}

	&__logo {
		.TopBarItem(250,@width,@height,auto,27,left,456);
	}

	&__badges {
		@media @mobile {
			display: flex;
			justify-content: center;
			margin-top: 14px;
		}
	}

	&__badge {
		@media @mobile {
			width: 75px;
			margin: 0 6px;
			align-self: center;
		}
	}

	&__rpii {
		.TopBarItem(90,@width,@height,auto,27,right,311);

		@media @mobile {
			width: 82px;
			height: 64px;
		}
	}

	&__tipe {
		.TopBarItem(70,@width,@height,auto,27,right,234);

		@media @mobile {
			width: 63px;
			height: 66px;
		}
	}

	&__bouncyCastleNetworkLink {
		.TopBarItem(100,@width,@height,auto,27,right,122);

		@media @mobile {
			width: 91px;
			height: 59px;
		}
	}

	&__biha {
		.TopBarItem(76,@width,@height,auto,27,right,30);

		@media @mobile {
			width: 69px;
			height: 74px;
		}
	}

	&__weather {
		.TopBarItem(247,@width,@height,auto,146,left,30);

		@media @mobile {
			text-align: center;
			display: block;
		}
	}

	&__call {
		color: #ffd300;
		line-height: 1;
		white-space: nowrap;
		.TopBarItem(269,@width,@height,auto,84,left,30);
		.LondrindaSolidBlack;

		@media @mobile {
			text-align: center;
			font-size: 32px;
			margin-top: 20px;
		}

		@media @tablet {
			font-size: 3.7vw;
		}

		@media @desktop {
			font-size: 52px;
		}
	}

	&__phone {
		color: #ffffff;
		line-height: 1;
		white-space: nowrap;
		.TopBarItem(269,@width,@height,auto,30,left,30);
		.LondrindaSolidBlack;

		&:hover {
			color: #ffffff;
		}

		@media @mobile {
			text-align: center;
			font-size: 32px;
			display: block;
			padding: 8px 0;
		}

		@media @tablet {
			font-size: 3.7vw;
		}

		@media @desktop {
			font-size: 52px;
		}
	}
	/*&__phone-2 {
		.TopBarItem(269,@width,@height,auto,47,left,30);
	}*/
	&__social-links {
		.TopBarItem(350,@width,@height,auto,153,right,33);
		display: flex;
		justify-content: center;

		@media @tablet {
			justify-content: flex-end;
		}

		@media @mobile {
			padding: 15px 0px;
		}
	}

	&__social-link {
		@media @tablet {
			width: 14%;
			margin: 0 1%;
		}

		@media @mobile {
			width: 45px;
			margin: 0 1%;
			justify-content: center;
		}
	}
}

.outer-wrapper--alt {
	.header {
		@media @mobile {
			padding-top: 80px;
		}
	}
}

#BodyContent {
	border-top-width: 0px;

	h1 {
		margin-bottom: 40px;
	}

	p {
		font-size: 18px;
	}
}

.pageListingsSection {
	&__header {
		font-size: 20px;
		color: #1fae37;
		padding-top: 50px;
		font-size: 55px;
		text-align: center;
		.LondrindaSolidBlack;
	}

	&__kids-left {
		position: absolute;
		bottom: 0;
		left: 0;
		transform: translateX(-90%);

		@media @mobile {
			display: none;
		}
	}

	&__kids-right {
		position: absolute;
		bottom: 0;
		right: 0;
		transform: translateX(92%);

		@media @mobile {
			display: none;
		}
	}

	.castleCheckBook {
		width: 50%;
		margin: 4% 25%;
		border-radius: 14px;
	}

	.castleTitlePanel a.castleLink {
		color: #ff8200;
	}

	.view-all-button {
		padding: 12px 45px;
		background-color: red;
		margin-top: 30px;
		color: white;
		font-weight: bold;
		letter-spacing: 2px;
		text-transform: uppercase;
	}
}

.feature-categories {
	background-image: url('@{cloudUrl}featured-bg.jpg');
	padding-bottom: 100px;

	&__title {
		color: white;
		font-size: 30px;
		.LondrindaSolidBlack;
		letter-spacing: 2px;

		@media @mobile {
			text-align: center;
		}
	}

	&__body {
		color: white;
		font-size: 20px;

		@media @mobile {
			text-align: center;
		}
	}

	&__wrapper {
		padding: 15px;
	}

	&__all-products {
		background-color: #45cf4d;

		&:before {
			.StickToEdges;
			background-image: url('@{cloudUrl}all-products-bg.png');
			opacity: 0.4;
		}

		border-radius: 14px;
		margin-top: 10px;
		padding: 16px;
	}

	&__header {
		font-size: 20px;
		color: white;
		padding-top: 50px;
		.LondrindaSolidBlack;
		font-size: 55px;
		text-align: center;
	}

	.carousel-inner {
		width: 100%;

		img {
			border-radius: 19px;

			@media @mobile {
				width: 100%;
				margin-bottom: 15px;
			}

			@media @tablet {
				width: 100%;
				margin-bottom: 15px;
			}
		}
	}

	&__items {
		margin-top: 39px;
	}

	&__category-image {
		transform: scale(1.3);
	}

	&__gallery {

		#CarouselContainer .carousel-inner {

			.item {
				height: 100%;
			}

			a img, img {
				height: 100%;
				object-fit: contain;
				object-position: center center;
			}
		}

		&--01, &--02, &--03 {
			.BannerPanelSet(42.57%);
		}

		&--04 {
			.BannerPanelSet(45.14%);
		}
	}
}

.book-online {
	display: none;

	@media @tablet {
		display: block;
		position: relative;
		padding-bottom: 50px;

		&__title {
			position: relative;
			width: 76.85%;
			top: 18.18%;
			left: 4.02%;
			display: block;

			&--us-override {
				width: 92.85%;
			}
		}

		.card-logos {
			height: 22px;
			margin-top: 12px;
			margin-right: 32px;
		}

		&__date, &__category, &__button, #PostcodeSearchLeft select, #PostcodeSearchLeft input {
			height: 46px;
			top: 54%;
			position: absolute;
			border: none;
			border-radius: 5px;
			padding-left: 10px;
		}

		&__date, &__category, #PostcodeSearchLeft select, #PostcodeSearchLeft input {
			width: 23.7%;
		}

		&__top-bo-text {
			/*position:absolute;
			top:0;*/
			background-color: #0a2f6c;
			border-bottom-left-radius: 17px;
			border-bottom-right-radius: 17px;
			height: 60px;
			color: #ffd300;
			font-size: 4rem;
			.LondrindaSolidBlack;
			text-align: center;
			letter-spacing: 2px;
		}

		&__rotate {
			transform: rotate(90deg);
			position: absolute;
			right: 40px;
			color: white;
		}

		&__button {
			background: #ffd50d;
			width: 15.76%;
			left: 81.2%;
			border-radius: 10px;
			border: 1px solid transparent;
			-webkit-transition: all .3s;
			transition: all .3s;
			font-family: 'Londrina Solid',cursive;
			font-weight: 900;
			font-size: 28px;
			text-align: center;
			color: #5f5318;
			line-height: 1.62;
			padding-left: 0;

			&:hover {
				border: 1px solid #5f5318;
				color: #5f5318;
			}

			&:before {
				content: "";
				.TransformMiddle;
				width: 80%;
				padding-top: 12.98%;
				/*background-image: url("@{cloudUrl}search-now.png");*/
				background-size: 100% 100%;
			}
		}

		#PostcodeSearchLeft select, #PostcodeSearchLeft input {
			left: 4.23%;
		}

		&__date {
			left: 30%;
		}

		&__category {
			left: 55.8%;
		}

		#PostcodeSearchLeft select, #PostcodeSearchLeft input {
			@media @desktop {
				background-repeat: no-repeat;
				background-size: 38px 40px;
				background-position: 93% center;
			}
		}

		&__date {
			background-repeat: no-repeat;
			background-size: 54px 46px;
			background-position: 95% center;
		}

		&__category {
			background-repeat: no-repeat;
			background-size: 38px 40px;
			background-position: 88% center;
			background-color: white;
		}
	}
}

.enquiryform {
	position:relative;

	&__bg {
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		width: 100%;
		height: 100%;
		object-fit: cover;
	}

	&__form-wrapper {
		margin-top: 27px;
	}

	&__image-left {
		display: none;

		@media @desktop {
			display: block;
			transform: translate(-125px, 21px);
		}
		/*@media @mobile {
			display: none;
		}
		@media @tablet {
			display: none;
		}*/
	}

	.sectionTitle {
		display: none;
	}

	.Label-Name, .Label-Email,
	.Label-Phone, .Label-Message, .Label-YourLocation {
		display: none;
	}

	.ControlPhone, .ControlName,
	.ControlEmail, .ControlMessage, .ControlYourLocation {
		background-color: #aa7c09;
		color: white;
		border: 1px solid #181818;
		border-radius: 5px;
		width: 100%;
		height: 60px;

		&::placeholder {
			color: #fff
		}
	}

	.innerColumn {
		padding: 0px;

		.col-sm-6 {
			width: 100%;
		}
	}

	.booking-form {
		max-width: 540px;
		margin: 0px;
		border: 0;
		background-color: transparent;
		border-radius: 0 0 0 0;
		color: #4d4d4d;
		box-shadow: 0px 0px 0px #000;

		@media @mobile {
			margin: 0 auto 30px;
		}
	}

	h3 {
		.LondrindaSolidBlack;
		font-size: 5rem;
	}

	p {
		font-size: 25px;
	}

	&__form-text {
		margin-bottom: 30px;
		max-width: 540px;

		@media @mobile {
			text-align: center;
			margin: 0 auto 30px;
		}
	}

	input[type='button'] {
		.LondrindaSolidBlack;
		color: white;
		background-color: #181818;
		letter-spacing: 2px;
		font-size: 2rem;
		padding: 15px;
		margin: 20px 0px 40px;
	}
}

.banner {
	.BannerPanelSet(44.7%);
	position: relative;
	/// kids position:absolute;
	&__kids-left {
		position: absolute;
		bottom: 0;
		left: 0;
		transform: translateX(-76%);

		@media @mobile {
			display: none;
		}
	}

	&__kids-right {
		position: absolute;
		bottom: 0;
		right: 0;
		transform: translateX(80%);

		@media @mobile {
			display: none;
		}
	}
}

.castlePanel {
	width: 100%;
	margin: 10px 0;
	display: flex;
	flex-direction: column;
	border: 1px solid #cfcfcf;
	border-radius: 5px;
	overflow: hidden;

	&:hover {
	}

	.SingleImagePanel {
		height: auto;
	}

	.castleTitlePanel {
		margin: 10px 0 0;
		padding: 0 5%;
		display: flex;
		justify-content: center;
		align-items: center;

		a {
			//font-size: 0.9rem;
		}
	}

	.castlePriceDefault, .castlePriceDiscounted {
		padding: 0;
		margin-top: 10px;
		//font-size: 1rem;
		span.oldprice {
			//font-size: 0.65rem;
		}

		color: #34b841;

		span.newprice {
			//font-size: 0.8rem;
		}

		.LondrindaSolidBlack;
		font-size: 2rem;
	}

	span.oldprice, span.newprice {
	}

	.castleCheckBook {
		margin-top: 10px;
		height: auto;
	}
}

.DetailsTitle h1, .DetailsTitle h2, .DetailsMainImage {
	background-color: @castleDetailsColor;
}

.BookButtons {
	text-align: center;

	.BookButton, .CheckButton {
		width: 180px;
		margin-left: auto;
		float: none;
		margin: 0 10px 10px;
	}
}
// tidy up
.footer {
	background: #000;
	padding: 20px 0 50px;
	color: #a1a1a1;
	height: 165px;

	@media @mobile {
		text-align: center;
	}

	&__flex-row-sm {
		@media @sm {
			display: flex;
			align-items: center;
		}
	}

	&__text-center-sm {
		@media @sm {
			text-align: center;
		}
	}

	&__text-right-sm {
		@media @sm {
			text-align: right;
		}
	}

	&__links {
		text-align: center;
		margin-bottom: 20px;
	}

	&__link {
		margin: 0 5px;
		color: #a1a1a1;

		&:hover {
			color: #a1a1a1;
		}
	}

	#BCNLink {
		color: #a1a1a1;

		&:hover {
			color: #a1a1a1;
		}
	}
}
/*#region Animations*/
.wow {
	visibility: hidden;
}

.animated {
	animation-duration: 1s;
	animation-fill-mode: both
}

@keyframes fadeIn {
	0% {
		opacity: 0
	}

	to {
		opacity: 1
	}
}

.fadeIn {
	animation-name: fadeIn
}
/*#endregion Animations*/
