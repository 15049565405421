/*#region paddingTop*/

.padding-top-10 {
    padding-top: 10px;
}

.padding-top-15 {
    padding-top: 15px;
}

.padding-top-20 {
    padding-top: 20px;
}

.padding-top-25 {
    padding-top: 25px;
}

.padding-top-35 {
    padding-top: 35px;
}

.padding-top-40 {
    padding-top: 40px;
}

.padding-top-50 {
    padding-top: 50px;
}

.padding-top-60 {
    padding-top: 60px;
}

.padding-top-80 {
    padding-top: 80px;
}

.padding-top-100 {
    padding-top: 100px;
}

/*#endregion paddingTop*/

/*#region paddingBottom*/
.padding-bottom-10 {
    padding-bottom: 10px;
}

.padding-bottom-15 {
    padding-bottom: 15px;
}

.padding-bottom-20 {
    padding-bottom: 20px;
}

.padding-bottom-25 {
    padding-bottom: 25px;
}

.padding-bottom-30 {
    padding-bottom: 30px;
}

.padding-bottom-35 {
    padding-bottom: 35px;
}

.padding-bottom-40 {
    padding-bottom: 40px;
}

.padding-bottom-50 {
    padding-bottom: 50px;
}

.padding-bottom-60 {
    padding-bottom: 60px;
}

.padding-bottom-80 {
    padding-bottom: 80px;
}

.padding-bottom-100 {
    padding-bottom: 100px;
}
/*#endregion paddingBottom*/

/*#region paddingY*/

.padding-y-10 {
    padding-top: 10px;
    padding-bottom: 10px;
}

.padding-y-15 {
    padding-top: 15px;
    padding-bottom: 15px;
}

.padding-y-20 {
    padding-top: 20px;
    padding-bottom: 20px;
}

.padding-y-25 {
    padding-top: 25px;
    padding-bottom: 25px;
}

.padding-y-30 {
	padding-top: 30px;
	padding-bottom: 30px;
}

.padding-y-35 {
    padding-top: 35px;
    padding-bottom: 35px;
}

.padding-y-40 {
    padding-top: 40px;
    padding-bottom: 40px;
}

.padding-y-50 {
    padding-top: 50px;
    padding-bottom: 50px;
}

.padding-y-60 {
    padding-top: 60px;
    padding-bottom: 60px;
}

.padding-y-80 {
    padding-top: 80px;
    padding-bottom: 80px;
}

.padding-y-100 {
    padding-top: 100px;
    padding-bottom: 100px;
}

/*#endregion paddingY*/

/*#region paddingX*/

.padding-x-10p {
    padding: 0 10%;
}

.padding-x-10 {
    padding: 0 10px;
}

.padding-x-15 {
    padding: 0 15px;
}

.padding-x-20 {
    padding: 0 20px;
}



/*#endregion paddingX*/

/*#region marginTop*/

.margin-top-0 {
    margin-top: 0;
}

.margin-top-10 {
    margin-top: 10px;
}

.margin-top-15 {
    margin-top: 15px;
}

.margin-top-20 {
    margin-top: 20px;
}


.margin-top-25 {
    margin-top: 25px;
}

.margin-top-30 {
    margin-top: 30px;
}

.margin-top-35 {
    margin-top: 35px;
}

.margin-top-40 {
    margin-top: 40px;
}

.margin-top-50 {
    margin-top: 50px;
}

.margin-top-60 {
    margin-top: 60px;
}

.margin-top-80 {
    margin-top: 80px;
}

.margin-top-100 {
    margin-top: 100px;
}

/*#endregion marginTop*/

/*#region marginBottom*/
.margin-bottom-10 {
    margin-bottom: 10px;
}

.margin-bottom-15 {
    margin-bottom: 15px;
}

.margin-bottom-20 {
    margin-bottom: 20px;
}

.margin-bottom-25 {
    margin-bottom: 25px;
}
.margin-bottom-30 {
    margin-bottom: 30px;
}

.margin-bottom-35 {
    margin-bottom: 35px;
}

.margin-bottom-40 {
    margin-bottom: 40px;
}

.margin-bottom-50 {
    margin-bottom: 50px;
}

.margin-bottom-60 {
    margin-bottom: 60px;
}

.margin-bottom-80 {
    margin-bottom: 80px;
}

.margin-bottom-100 {
    margin-bottom: 100px;
}
/*#endregion marginBottom*/

/*#region marginY*/

.margin-y-10 {
    margin-top: 10px;
    margin-bottom: 10px;
}

.margin-y-15 {
    margin-top: 15px;
    margin-bottom: 15px;
}

.margin-y-20 {
    margin-top: 20px;
    margin-bottom: 20px;
}

.margin-y-25 {
    margin-top: 25px;
    margin-bottom: 25px;
}

.margin-y-30 {
    margin-top: 30px;
    margin-bottom: 30px;
}


.margin-y-35 {
    margin-top: 35px;
    margin-bottom: 35px;
}

.margin-y-40 {
    margin-top: 40px;
    margin-bottom: 40px;
}

.margin-y-50 {
    margin-top: 50px;
    margin-bottom: 50px;
}

.margin-y-60 {
    margin-top: 60px;
    margin-bottom: 60px;
}

.margin-y-80 {
    margin-top: 80px;
    margin-bottom: 80px;
}

.margin-y-100 {
    margin-top: 100px;
    margin-bottom: 100px;
}

/*#endregion marginY*/

.white {
    color: #FFF;
}

.black {
    color: #000;
}

.container-bg-white {
    background-color: #fff;
}

.uppercase {
    text-transform: uppercase;
}

.relative {
    position: relative;
}

.display-inline-block {
    display: inline-block;
}

.display-block {
    display: block;
}

img.HoverBounce,
.HoverBounce img {
    -webkit-transform: translateZ(0);
            transform: translateZ(0);
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
    -webkit-transition: -webkit-transform 0.5s ease-in-out;
    transition: -webkit-transform 0.5s ease-in-out;
    transition: transform 0.5s ease-in-out;
    transition: transform 0.5s ease-in-out, -webkit-transform 0.5s ease-in-out;
    display: block;
    overflow: hidden;
}

    img.HoverBounce :hover,
    .HoverBounce img :hover {
        -webkit-transform: scale(1.1);
                transform: scale(1.1);
        -webkit-transition-timing-function: cubic-bezier(0.47, 2.02, 0.31, -0.36);
                transition-timing-function: cubic-bezier(0.47, 2.02, 0.31, -0.36);
        z-index: 100;
    }

a.HoverBounce {
    display: block;
}

    a.HoverBounce img {
        -webkit-transform: translateZ(0);
                transform: translateZ(0);
        -webkit-backface-visibility: hidden;
                backface-visibility: hidden;
        -webkit-transition: -webkit-transform 0.5s ease-in-out;
        transition: -webkit-transform 0.5s ease-in-out;
        transition: transform 0.5s ease-in-out;
        transition: transform 0.5s ease-in-out, -webkit-transform 0.5s ease-in-out;
        display: block;
        overflow: hidden;
    }

    a.HoverBounce :hover {
        -webkit-transform: scale(1.1);
                transform: scale(1.1);
        -webkit-transition-timing-function: cubic-bezier(0.47, 2.02, 0.31, -0.36);
                transition-timing-function: cubic-bezier(0.47, 2.02, 0.31, -0.36);
        z-index: 100;
    }

/*#region fonts*/
.OpenSans,
.BodyFont {
  font-family: 'Open Sans', sans-serif;
  font-weight: 400 !important;
}
.LondrindaSolidBlack {
  font-family: 'Londrina Solid', cursive;
  font-weight: 900;
}
body {
  font-family: 'Open Sans', sans-serif;
  font-weight: 400 !important;
}
.textContent h1 {
  font-size: 20px;
  padding-top: 50px;
  font-family: 'Londrina Solid', cursive;
  font-weight: 900;
  font-size: 55px;
  text-align: center;
}
/*#endregion fonts*/
form {
  max-width: 100vw;
  overflow: hidden;
}
.outer-wrapper {
  max-width: 100vw;
  position: relative;
}
.container {
  position: relative;
}
@media (max-width: 767px) {
  .container {
    width: 100%;
  }
}
@media (min-width: 768px) {
  .container {
    width: 100%;
  }
}
@media (min-width: 1200px) {
  .container {
    width: 1170px;
  }
}
@media (min-width: 1310px) {
  .container {
    padding-left: 55px;
    padding-right: 55px;
  }
}
.container--content {
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
}
/*#region topLinksMobile*/
#TopLinksMobile {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background: #2c5ca8;
  color: white;
  z-index: 20;
  padding: 0;
  border-bottom: 1px solid white;
  font-family: 'Open Sans', sans-serif;
  font-weight: 400 !important;
}
#TopLinksMobile .navbar {
  margin-bottom: 0;
}
@media (min-width: 768px) {
  #TopLinksMobile {
    display: none !important;
  }
}
#TopLinksMobile #NavigationToggleText {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  padding: 14px 63px;
  text-align: right;
}
#TopLinksMobile .navbar-toggle .icon-bar {
  background: white;
}
#TopLinksMobile .row {
  padding-top: 10px;
}
#TopLinksMobile .input-group {
  margin-bottom: 0;
}
#MobileHomeLink {
  position: fixed;
  top: 10px;
  z-index: 10;
  background: transparent none repeat scroll 0 0;
  color: white;
  left: 10px;
  border-radius: 50%;
  border: 2px solid white;
}
/*#endregion topLinksMobile*/
.toplinks {
  margin-bottom: -5px;
}
.toplinks__nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: baseline;
      -ms-flex-align: baseline;
          align-items: baseline;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin-bottom: 0;
  padding-left: 0;
}
.toplinks__li {
  position: relative;
  list-style: none;
  text-align: center;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
}
.toplinks__li:nth-child(5n + 1) a {
  background-color: #f72223;
}
.toplinks__li:nth-child(5n + 2) a {
  background-color: #ffcd00;
}
.toplinks__li:nth-child(5n + 3) a {
  background-color: #0dafee;
}
.toplinks__li:nth-child(5n + 4) a {
  background-color: #1fae37;
}
.toplinks__li:nth-child(5n + 5) a {
  background-color: #f7af00;
}
.toplinks__li .dropdown-toggle:focus {
  text-decoration: none;
}
.toplinks__link {
  position: relative;
  display: block;
  padding: 15px 5px 20px;
  color: #fff;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  font-size: 20px;
  font-family: 'Londrina Solid', cursive;
  font-weight: 900;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
}
.toplinks__link:hover,
.toplinks__link.active,
.toplinks__link:focus {
  /*color: #ffc600;*/
  border-radius: 5px 5px 0 0;
  color: white;
  -webkit-transform: translateY(-5px);
          transform: translateY(-5px);
}
.toplinks__dropdown {
  padding: 0;
  margin: 0;
  border: none;
  -webkit-box-shadow: none;
          box-shadow: none;
  text-align: center;
  background-color: inherit !important;
  background: #000;
  width: 100%;
}
.toplinks__dropdown-li {
  display: block;
}
.toplinks__dropdown-link {
  position: relative;
  padding: 10px 10px !important;
  font-size: 16px;
  font-family: 'Londrina Solid', cursive;
  font-weight: 900;
}
.toplinks__dropdown-link:hover,
.toplinks__dropdown-link:focus,
.toplinks__dropdown-link.active {
  background-color: black !important;
  color: #f7af00 !important;
}
.book-online {
  background: #ee1e19;
}
.top-bar {
  background-color: #0a2f6c;
}
.top-bar__opening-times {
  color: white;
}
.top-bar__top-links a {
  color: white;
  margin-left: 20px;
}
.top-bar__top-links a:hover {
  color: gold;
}
@media (max-width: 767px) {
  .top-bar {
    padding-top: 70px;
    display: none;
  }
}
.banner-wrapper {
  background-image: url('https://files.bookingonline.co.uk/image/upload/f_auto/themes/036/banner-bg.jpg');
  background-size: cover;
  padding-top: 70px;
}
.videoSection {
  height: 882px;
  position: relative;
}
@media (max-width: 767px) {
  .videoSection {
    height: 500px;
  }
}
.videoSection__bg {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.videoSection__video-left {
  position: absolute;
  bottom: 0;
  left: 0;
  -webkit-transform: translate(-88%, 5%);
          transform: translate(-88%, 5%);
  z-index: 5;
}
.videoSection__video__panel {
  position: relative;
  z-index: 10;
}
.videoSection__video__panel:before {
  content: "";
  display: block;
  width: 100%;
  padding-top: 56.25%;
}
.videoSection__video__panel iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.videoSection__video-right {
  position: absolute;
  bottom: 0;
  right: 0;
  -webkit-transform: translate(81%, -12%);
          transform: translate(81%, -12%);
  z-index: 5;
}
.videoSection__callUs {
  text-transform: uppercase;
  background-color: #fff;
  color: #f00;
  border-radius: 5px;
  padding: 10px 79px;
  font-family: 'Londrina Solid', cursive;
  font-weight: 900;
  font-size: 3rem;
  margin: 70px 70px 70px;
  display: inline-block;
}
@media (max-width: 767px) {
  .videoSection__callUs {
    margin: 31px 0 31px;
  }
}
.testimonials {
  /*background-color: #edeff2;*/
  background-color: white;
  position: relative;
  padding: 30px 30px 60px;
  z-index: 1;
  -webkit-box-shadow: 0 5px 4px 0 rgba(49, 59, 73, 0.3);
  box-shadow: 0 5px 4px 0 rgba(49, 59, 73, 0.3);
}
@media (max-width: 767px) {
  .testimonials {
    margin-top: -40px;
    padding: 15px 15px 30px;
  }
}
.testimonials__header {
  color: #ffcd00;
  font-family: 'Londrina Solid', cursive;
  font-weight: 900;
  font-size: 5rem;
  margin-bottom: 41px;
}
.testimonials__view-all {
  background-color: #a7a7a7;
  margin-top: 40px;
  padding: 13px 39px;
  color: #fff;
  letter-spacing: 3px;
  font-family: 'Londrina Solid', cursive;
  font-weight: 900;
  font-size: 2rem;
}
.testimonials:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAOAgMAAABiJsVCAAABS2lUWHRYTUw6Y29tLmFkb2JlLnhtcAAAAAAAPD94cGFja2V0IGJlZ2luPSLvu78iIGlkPSJXNU0wTXBDZWhpSHpyZVN6TlRjemtjOWQiPz4KPHg6eG1wbWV0YSB4bWxuczp4PSJhZG9iZTpuczptZXRhLyIgeDp4bXB0az0iQWRvYmUgWE1QIENvcmUgNS42LWMxMzggNzkuMTU5ODI0LCAyMDE2LzA5LzE0LTAxOjA5OjAxICAgICAgICAiPgogPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4KICA8cmRmOkRlc2NyaXB0aW9uIHJkZjphYm91dD0iIi8+CiA8L3JkZjpSREY+CjwveDp4bXBtZXRhPgo8P3hwYWNrZXQgZW5kPSJyIj8+IEmuOgAAAARnQU1BAACxjwv8YQUAAAABc1JHQgCuzhzpAAAADFBMVEUAAAAAAAAAAAAAAAA16TeWAAAABHRSTlMADQcaCWiadAAAAChJREFUCNdjuMDAIMAQxsCgwCDLAASqCUCCESjIwBQG4hMWTGBgCgAA8yMHEmDIg7sAAAAASUVORK5CYII=') /*diagonal-dots.png*/;
  background-size: 7px;
  z-index: -1;
}
.testimonials__link {
  text-align: center;
}
.testimonials__text {
  color: #808080;
}
.testimonials .RotatorTestimonial {
  background: #fff;
  border-radius: 5px;
  border: 1px solid #cfcfcf;
  -webkit-box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.15);
          box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.15);
  margin: 0 8px;
  padding: 15px;
  text-align: center;
  overflow: hidden;
}
@media (min-width: 768px) {
  .testimonials .RotatorTestimonial {
    padding: 30px;
  }
}
.testimonials .RotatorTestimonialContent {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: reverse;
      -ms-flex-direction: column-reverse;
          flex-direction: column-reverse;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.testimonials .RotatorTestimonialContent time {
  display: none;
}
.testimonials .RotatorTestimonialContent p:first-child {
  color: #fff;
}
.testimonials .RotatorTestimonialContent p:first-child a {
  color: #2babbf;
  font-family: 'Londrina Solid', cursive;
  font-weight: 900;
}
.testimonials .slick-track {
  padding-bottom: 15px;
}
.testimonials #RotatorTestimonialsHeader {
  margin-bottom: 15px;
}
.testimonials #RotatorTestimonialsHeader p,
.testimonials #RotatorTestimonialsHeader a {
  text-align: center;
  color: #333;
  font-size: 2rem;
  position: relative;
}
.testimonials .RotatorTestimonialRating {
  max-width: 280px;
  margin: 0 auto;
}
.header {
  position: relative;
  /*&__phone-2 {
		.TopBarItem(269,@width,@height,auto,47,left,30);
	}*/
}
@media (min-width: 768px) {
  .header {
    padding-top: 20.17%;
  }
}
@media (max-width: 767px) {
  .header {
    padding-top: 30px;
  }
}
@media (max-width: 767px) {
  .header__logo {
    margin: 10px auto;
    width: auto;
  }
}
@media (min-width: 768px) {
  .header__logo {
    position: absolute;
    width: 21.37%;
    bottom: 11.44%;
    left: 38.97%;
  }
}
@media (max-width: 767px) {
  .header__badges {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    margin-top: 14px;
  }
}
@media (max-width: 767px) {
  .header__badge {
    width: 75px;
    margin: 0 6px;
    -ms-flex-item-align: center;
        align-self: center;
  }
}
@media (max-width: 767px) {
  .header__rpii {
    margin: 10px auto;
    width: auto;
  }
}
@media (min-width: 768px) {
  .header__rpii {
    position: absolute;
    width: 7.69%;
    bottom: 11.44%;
    right: 26.58%;
  }
}
@media (max-width: 767px) {
  .header__rpii {
    width: 82px;
    height: 64px;
  }
}
@media (max-width: 767px) {
  .header__tipe {
    margin: 10px auto;
    width: auto;
  }
}
@media (min-width: 768px) {
  .header__tipe {
    position: absolute;
    width: 5.98%;
    bottom: 11.44%;
    right: 20%;
  }
}
@media (max-width: 767px) {
  .header__tipe {
    width: 63px;
    height: 66px;
  }
}
@media (max-width: 767px) {
  .header__bouncyCastleNetworkLink {
    margin: 10px auto;
    width: auto;
  }
}
@media (min-width: 768px) {
  .header__bouncyCastleNetworkLink {
    position: absolute;
    width: 8.55%;
    bottom: 11.44%;
    right: 10.43%;
  }
}
@media (max-width: 767px) {
  .header__bouncyCastleNetworkLink {
    width: 91px;
    height: 59px;
  }
}
@media (max-width: 767px) {
  .header__biha {
    margin: 10px auto;
    width: auto;
  }
}
@media (min-width: 768px) {
  .header__biha {
    position: absolute;
    width: 6.5%;
    bottom: 11.44%;
    right: 2.56%;
  }
}
@media (max-width: 767px) {
  .header__biha {
    width: 69px;
    height: 74px;
  }
}
@media (max-width: 767px) {
  .header__weather {
    margin: 10px auto;
    width: auto;
  }
}
@media (min-width: 768px) {
  .header__weather {
    position: absolute;
    width: 21.11%;
    bottom: 61.86%;
    left: 2.56%;
  }
}
@media (max-width: 767px) {
  .header__weather {
    text-align: center;
    display: block;
  }
}
.header__call {
  color: #ffd300;
  line-height: 1;
  white-space: nowrap;
  font-family: 'Londrina Solid', cursive;
  font-weight: 900;
}
@media (max-width: 767px) {
  .header__call {
    margin: 10px auto;
    width: auto;
  }
}
@media (min-width: 768px) {
  .header__call {
    position: absolute;
    width: 22.99%;
    bottom: 35.59%;
    left: 2.56%;
  }
}
@media (max-width: 767px) {
  .header__call {
    text-align: center;
    font-size: 32px;
    margin-top: 20px;
  }
}
@media (min-width: 768px) {
  .header__call {
    font-size: 3.7vw;
  }
}
@media (min-width: 1200px) {
  .header__call {
    font-size: 52px;
  }
}
.header__phone {
  color: #ffffff;
  line-height: 1;
  white-space: nowrap;
  font-family: 'Londrina Solid', cursive;
  font-weight: 900;
}
@media (max-width: 767px) {
  .header__phone {
    margin: 10px auto;
    width: auto;
  }
}
@media (min-width: 768px) {
  .header__phone {
    position: absolute;
    width: 22.99%;
    bottom: 12.71%;
    left: 2.56%;
  }
}
.header__phone:hover {
  color: #ffffff;
}
@media (max-width: 767px) {
  .header__phone {
    text-align: center;
    font-size: 32px;
    display: block;
    padding: 8px 0;
  }
}
@media (min-width: 768px) {
  .header__phone {
    font-size: 3.7vw;
  }
}
@media (min-width: 1200px) {
  .header__phone {
    font-size: 52px;
  }
}
.header__social-links {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
@media (max-width: 767px) {
  .header__social-links {
    margin: 10px auto;
    width: auto;
  }
}
@media (min-width: 768px) {
  .header__social-links {
    position: absolute;
    width: 29.91%;
    bottom: 64.83%;
    right: 2.82%;
  }
}
@media (min-width: 768px) {
  .header__social-links {
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end;
  }
}
@media (max-width: 767px) {
  .header__social-links {
    padding: 15px 0px;
  }
}
@media (min-width: 768px) {
  .header__social-link {
    width: 14%;
    margin: 0 1%;
  }
}
@media (max-width: 767px) {
  .header__social-link {
    width: 45px;
    margin: 0 1%;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
}
@media (max-width: 767px) {
  .outer-wrapper--alt .header {
    padding-top: 80px;
  }
}
#BodyContent {
  border-top-width: 0px;
}
#BodyContent h1 {
  margin-bottom: 40px;
}
#BodyContent p {
  font-size: 18px;
}
.pageListingsSection__header {
  font-size: 20px;
  color: #1fae37;
  padding-top: 50px;
  font-size: 55px;
  text-align: center;
  font-family: 'Londrina Solid', cursive;
  font-weight: 900;
}
.pageListingsSection__kids-left {
  position: absolute;
  bottom: 0;
  left: 0;
  -webkit-transform: translateX(-90%);
          transform: translateX(-90%);
}
@media (max-width: 767px) {
  .pageListingsSection__kids-left {
    display: none;
  }
}
.pageListingsSection__kids-right {
  position: absolute;
  bottom: 0;
  right: 0;
  -webkit-transform: translateX(92%);
          transform: translateX(92%);
}
@media (max-width: 767px) {
  .pageListingsSection__kids-right {
    display: none;
  }
}
.pageListingsSection .castleCheckBook {
  width: 50%;
  margin: 4% 25%;
  border-radius: 14px;
}
.pageListingsSection .castleTitlePanel a.castleLink {
  color: #ff8200;
}
.pageListingsSection .view-all-button {
  padding: 12px 45px;
  background-color: red;
  margin-top: 30px;
  color: white;
  font-weight: bold;
  letter-spacing: 2px;
  text-transform: uppercase;
}
.feature-categories {
  background-image: url('https://files.bookingonline.co.uk/image/upload/f_auto/themes/036/featured-bg.jpg');
  padding-bottom: 100px;
}
.feature-categories__title {
  color: white;
  font-size: 30px;
  font-family: 'Londrina Solid', cursive;
  font-weight: 900;
  letter-spacing: 2px;
}
@media (max-width: 767px) {
  .feature-categories__title {
    text-align: center;
  }
}
.feature-categories__body {
  color: white;
  font-size: 20px;
}
@media (max-width: 767px) {
  .feature-categories__body {
    text-align: center;
  }
}
.feature-categories__wrapper {
  padding: 15px;
}
.feature-categories__all-products {
  background-color: #45cf4d;
  border-radius: 14px;
  margin-top: 10px;
  padding: 16px;
}
.feature-categories__all-products:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-position: center center;
  background-repeat: no-repeat;
  background-image: url('https://files.bookingonline.co.uk/image/upload/f_auto/themes/036/all-products-bg.png');
  opacity: 0.4;
}
.feature-categories__header {
  font-size: 20px;
  color: white;
  padding-top: 50px;
  font-family: 'Londrina Solid', cursive;
  font-weight: 900;
  font-size: 55px;
  text-align: center;
}
.feature-categories .carousel-inner {
  width: 100%;
}
.feature-categories .carousel-inner img {
  border-radius: 19px;
}
@media (max-width: 767px) {
  .feature-categories .carousel-inner img {
    width: 100%;
    margin-bottom: 15px;
  }
}
@media (min-width: 768px) {
  .feature-categories .carousel-inner img {
    width: 100%;
    margin-bottom: 15px;
  }
}
.feature-categories__items {
  margin-top: 39px;
}
.feature-categories__category-image {
  -webkit-transform: scale(1.3);
          transform: scale(1.3);
}
.feature-categories__gallery #CarouselContainer .carousel-inner .item {
  height: 100%;
}
.feature-categories__gallery #CarouselContainer .carousel-inner a img,
.feature-categories__gallery #CarouselContainer .carousel-inner img {
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
  -o-object-position: center center;
     object-position: center center;
}
.feature-categories__gallery--01 #CarouselContainer,
.feature-categories__gallery--02 #CarouselContainer,
.feature-categories__gallery--03 #CarouselContainer {
  padding-top: 42.57%;
  overflow: hidden;
}
.feature-categories__gallery--01 #CarouselContainer .carousel-inner,
.feature-categories__gallery--02 #CarouselContainer .carousel-inner,
.feature-categories__gallery--03 #CarouselContainer .carousel-inner {
  position: absolute;
  top: 0;
  height: 100%;
}
.feature-categories__gallery--01 #CarouselContainer .carousel-inner .item,
.feature-categories__gallery--02 #CarouselContainer .carousel-inner .item,
.feature-categories__gallery--03 #CarouselContainer .carousel-inner .item {
  width: 100%;
}
.feature-categories__gallery--01 #CarouselContainer .carousel-inner .item a img,
.feature-categories__gallery--02 #CarouselContainer .carousel-inner .item a img,
.feature-categories__gallery--03 #CarouselContainer .carousel-inner .item a img,
.feature-categories__gallery--01 #CarouselContainer .carousel-inner .item img,
.feature-categories__gallery--02 #CarouselContainer .carousel-inner .item img,
.feature-categories__gallery--03 #CarouselContainer .carousel-inner .item img {
  width: 100%;
}
.feature-categories__gallery--04 #CarouselContainer {
  padding-top: 45.14%;
  overflow: hidden;
}
.feature-categories__gallery--04 #CarouselContainer .carousel-inner {
  position: absolute;
  top: 0;
  height: 100%;
}
.feature-categories__gallery--04 #CarouselContainer .carousel-inner .item {
  width: 100%;
}
.feature-categories__gallery--04 #CarouselContainer .carousel-inner .item a img,
.feature-categories__gallery--04 #CarouselContainer .carousel-inner .item img {
  width: 100%;
}
.book-online {
  display: none;
}
@media (min-width: 768px) {
  .book-online {
    display: block;
    position: relative;
    padding-bottom: 50px;
  }
  .book-online__title {
    position: relative;
    width: 76.85%;
    top: 18.18%;
    left: 4.02%;
    display: block;
  }
  .book-online__title--us-override {
    width: 92.85%;
  }
  .book-online .card-logos {
    height: 22px;
    margin-top: 12px;
    margin-right: 32px;
  }
  .book-online__date,
  .book-online__category,
  .book-online__button,
  .book-online #PostcodeSearchLeft select,
  .book-online #PostcodeSearchLeft input {
    height: 46px;
    top: 54%;
    position: absolute;
    border: none;
    border-radius: 5px;
    padding-left: 10px;
  }
  .book-online__date,
  .book-online__category,
  .book-online #PostcodeSearchLeft select,
  .book-online #PostcodeSearchLeft input {
    width: 23.7%;
  }
  .book-online__top-bo-text {
    /*position:absolute;
			top:0;*/
    background-color: #0a2f6c;
    border-bottom-left-radius: 17px;
    border-bottom-right-radius: 17px;
    height: 60px;
    color: #ffd300;
    font-size: 4rem;
    font-family: 'Londrina Solid', cursive;
    font-weight: 900;
    text-align: center;
    letter-spacing: 2px;
  }
  .book-online__rotate {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
    position: absolute;
    right: 40px;
    color: white;
  }
  .book-online__button {
    background: #ffd50d;
    width: 15.76%;
    left: 81.2%;
    border-radius: 10px;
    border: 1px solid transparent;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    font-family: 'Londrina Solid', cursive;
    font-weight: 900;
    font-size: 28px;
    text-align: center;
    color: #5f5318;
    line-height: 1.62;
    padding-left: 0;
  }
  .book-online__button:hover {
    border: 1px solid #5f5318;
    color: #5f5318;
  }
  .book-online__button:before {
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translateY(-50%) translateX(-50%);
    transform: translateY(-50%) translateX(-50%);
    width: 80%;
    padding-top: 12.98%;
    /*background-image: url("@{cloudUrl}search-now.png");*/
    background-size: 100% 100%;
  }
  .book-online #PostcodeSearchLeft select,
  .book-online #PostcodeSearchLeft input {
    left: 4.23%;
  }
  .book-online__date {
    left: 30%;
  }
  .book-online__category {
    left: 55.8%;
  }
  .book-online__date {
    background-repeat: no-repeat;
    background-size: 54px 46px;
    background-position: 95% center;
  }
  .book-online__category {
    background-repeat: no-repeat;
    background-size: 38px 40px;
    background-position: 88% center;
    background-color: white;
  }
}
@media (min-width: 768px) and (min-width: 1200px) {
  .book-online #PostcodeSearchLeft select,
  .book-online #PostcodeSearchLeft input {
    background-repeat: no-repeat;
    background-size: 38px 40px;
    background-position: 93% center;
  }
}
.enquiryform {
  position: relative;
}
.enquiryform__bg {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.enquiryform__form-wrapper {
  margin-top: 27px;
}
.enquiryform__image-left {
  display: none;
  /*@media @mobile {
			display: none;
		}
		@media @tablet {
			display: none;
		}*/
}
@media (min-width: 1200px) {
  .enquiryform__image-left {
    display: block;
    -webkit-transform: translate(-125px, 21px);
            transform: translate(-125px, 21px);
  }
}
.enquiryform .sectionTitle {
  display: none;
}
.enquiryform .Label-Name,
.enquiryform .Label-Email,
.enquiryform .Label-Phone,
.enquiryform .Label-Message,
.enquiryform .Label-YourLocation {
  display: none;
}
.enquiryform .ControlPhone,
.enquiryform .ControlName,
.enquiryform .ControlEmail,
.enquiryform .ControlMessage,
.enquiryform .ControlYourLocation {
  background-color: #aa7c09;
  color: white;
  border: 1px solid #181818;
  border-radius: 5px;
  width: 100%;
  height: 60px;
}
.enquiryform .ControlPhone::-webkit-input-placeholder,
.enquiryform .ControlName::-webkit-input-placeholder,
.enquiryform .ControlEmail::-webkit-input-placeholder,
.enquiryform .ControlMessage::-webkit-input-placeholder,
.enquiryform .ControlYourLocation::-webkit-input-placeholder {
  color: #fff;
}
.enquiryform .ControlPhone::-moz-placeholder,
.enquiryform .ControlName::-moz-placeholder,
.enquiryform .ControlEmail::-moz-placeholder,
.enquiryform .ControlMessage::-moz-placeholder,
.enquiryform .ControlYourLocation::-moz-placeholder {
  color: #fff;
}
.enquiryform .ControlPhone::-ms-input-placeholder,
.enquiryform .ControlName::-ms-input-placeholder,
.enquiryform .ControlEmail::-ms-input-placeholder,
.enquiryform .ControlMessage::-ms-input-placeholder,
.enquiryform .ControlYourLocation::-ms-input-placeholder {
  color: #fff;
}
.enquiryform .ControlPhone::placeholder,
.enquiryform .ControlName::placeholder,
.enquiryform .ControlEmail::placeholder,
.enquiryform .ControlMessage::placeholder,
.enquiryform .ControlYourLocation::placeholder {
  color: #fff;
}
.enquiryform .innerColumn {
  padding: 0px;
}
.enquiryform .innerColumn .col-sm-6 {
  width: 100%;
}
.enquiryform .booking-form {
  max-width: 540px;
  margin: 0px;
  border: 0;
  background-color: transparent;
  border-radius: 0 0 0 0;
  color: #4d4d4d;
  -webkit-box-shadow: 0px 0px 0px #000;
          box-shadow: 0px 0px 0px #000;
}
@media (max-width: 767px) {
  .enquiryform .booking-form {
    margin: 0 auto 30px;
  }
}
.enquiryform h3 {
  font-family: 'Londrina Solid', cursive;
  font-weight: 900;
  font-size: 5rem;
}
.enquiryform p {
  font-size: 25px;
}
.enquiryform__form-text {
  margin-bottom: 30px;
  max-width: 540px;
}
@media (max-width: 767px) {
  .enquiryform__form-text {
    text-align: center;
    margin: 0 auto 30px;
  }
}
.enquiryform input[type='button'] {
  font-family: 'Londrina Solid', cursive;
  font-weight: 900;
  color: white;
  background-color: #181818;
  letter-spacing: 2px;
  font-size: 2rem;
  padding: 15px;
  margin: 20px 0px 40px;
}
.banner {
  position: relative;
}
.banner #CarouselContainer {
  padding-top: 44.7%;
  overflow: hidden;
}
.banner #CarouselContainer .carousel-inner {
  position: absolute;
  top: 0;
  height: 100%;
}
.banner #CarouselContainer .carousel-inner .item {
  width: 100%;
}
.banner #CarouselContainer .carousel-inner .item a img,
.banner #CarouselContainer .carousel-inner .item img {
  width: 100%;
}
.banner__kids-left {
  position: absolute;
  bottom: 0;
  left: 0;
  -webkit-transform: translateX(-76%);
          transform: translateX(-76%);
}
@media (max-width: 767px) {
  .banner__kids-left {
    display: none;
  }
}
.banner__kids-right {
  position: absolute;
  bottom: 0;
  right: 0;
  -webkit-transform: translateX(80%);
          transform: translateX(80%);
}
@media (max-width: 767px) {
  .banner__kids-right {
    display: none;
  }
}
.castlePanel {
  width: 100%;
  margin: 10px 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  border: 1px solid #cfcfcf;
  border-radius: 5px;
  overflow: hidden;
}
.castlePanel .SingleImagePanel {
  height: auto;
}
.castlePanel .castleTitlePanel {
  margin: 10px 0 0;
  padding: 0 5%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.castlePanel .castlePriceDefault,
.castlePanel .castlePriceDiscounted {
  padding: 0;
  margin-top: 10px;
  color: #34b841;
  font-family: 'Londrina Solid', cursive;
  font-weight: 900;
  font-size: 2rem;
}
.castlePanel .castleCheckBook {
  margin-top: 10px;
  height: auto;
}
.DetailsTitle h1,
.DetailsTitle h2,
.DetailsMainImage {
  background-color: #2c5ca8;
}
.BookButtons {
  text-align: center;
}
.BookButtons .BookButton,
.BookButtons .CheckButton {
  width: 180px;
  margin-left: auto;
  float: none;
  margin: 0 10px 10px;
}
.footer {
  background: #000;
  padding: 20px 0 50px;
  color: #a1a1a1;
  height: 165px;
}
@media (max-width: 767px) {
  .footer {
    text-align: center;
  }
}
@media (min-width: 768px) {
  .footer__flex-row-sm {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
}
@media (min-width: 768px) {
  .footer__text-center-sm {
    text-align: center;
  }
}
@media (min-width: 768px) {
  .footer__text-right-sm {
    text-align: right;
  }
}
.footer__links {
  text-align: center;
  margin-bottom: 20px;
}
.footer__link {
  margin: 0 5px;
  color: #a1a1a1;
}
.footer__link:hover {
  color: #a1a1a1;
}
.footer #BCNLink {
  color: #a1a1a1;
}
.footer #BCNLink:hover {
  color: #a1a1a1;
}
/*#region Animations*/
.wow {
  visibility: hidden;
}
.animated {
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
}
@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.fadeIn {
  -webkit-animation-name: fadeIn;
          animation-name: fadeIn;
}
/*#endregion Animations*/
